import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
export const _frontmatter = {
  "title": "JS5 student free servers design doc",
  "path": "/knowledge/c0d3/docs",
  "date": "2022-08-25T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "js5-student-servers-design-doc",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#js5-student-servers-design-doc",
            "aria-label": "js5 student servers design doc permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`JS5 student servers design doc`}</h1>
        <p><strong parentName="p">{`Unfinished design doc`}</strong></p>
        <h2 {...{
          "id": "goals",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#goals",
            "aria-label": "goals permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Goals`}</h2>
        <ul>
          <li parentName="ul">{`After a student completes JS4, a server should be created for them which they'll use to practice the content of JS5 in it.`}</li>
        </ul>
        <h2 {...{
          "id": "action-items",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#action-items",
            "aria-label": "action items permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Action items`}</h2>
        <ol>
          <li parentName="ol">{`Get the student SSH key`}</li>
          <li parentName="ol">{`Create a server through `}<a parentName="li" {...{
              "href": "https://github.com/pjpimentel/dots"
            }}>{`DigitalOcean API`}</a></li>
          <li parentName="ol">{`Link the server IP to the student data in the DB`}</li>
          <li parentName="ol">{`Display the reward and how it could be redeemed`}</li>
        </ol>
        <h3 {...{
          "id": "get-the-student-ssh-key",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#get-the-student-ssh-key",
            "aria-label": "get the student ssh key permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Get the student SSH key`}</h3>
        <p>{`After the student completes JS4, The student will input their SSH key into a page under `}<inlineCode parentName="p">{`/[username]/server`}</inlineCode>{`, this SSH will be used in the next action item to ensure that the student will have access to the server (and the admins).`}</p>
        <h3 {...{
          "id": "create-a-server-droplet-through-digitalocean-api",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#create-a-server-droplet-through-digitalocean-api",
            "aria-label": "create a server droplet through digitalocean api permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Create a server (droplet) through DigitalOcean API`}</h3>
        <p>{`After getting the student SSH key, an action will auto be run that will send a POST request to the API endpoint `}<inlineCode parentName="p">{`https://api.digitalocean.com/v2/droplets`}</inlineCode>{` to create a droplet with the following payload including the student SSH key:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`{
  "name": "student.id.c0d3",
  "region": "student_chosen_region",
  "size": "s-1vcpu-1gb",
  "image": "ubuntu-20-04-x64",
  "ssh_keys": [
    289794,
    "3b:16:e4:bf:8b:00:8b:b8:59:8c:a9:d3:f0:19:fa:45",
    // Student SSH key and the admins
  ]
}
`}</code></pre>
        <p><a parentName="p" {...{
            "href": "https://dots.pimentel.co/src/droplet/#create-droplet"
          }}>{`Example code`}</a>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`try {
  const input = {
    name: 'example.com', // string
    region: 'nyc3', // string
    size: 's-1vcpu-1gb', // string
    image: 'ubuntu-16-04-x64', // string
    ssh_keys: ['Student_SSH_key', ...AdminSSHKeys],
  }
  const {
    data: { droplet },
  } = await dots.droplet.createDroplet(input)
  console.log(droplet)
} catch (error) {
  console.log(error)
}
`}</code></pre>
        <p>{`Example response:`}</p>
        <pre><code parentName="pre" {...{}}>{`{
"droplet": {
    "id": 3164444,
    "name": "example.com",
    "memory": 1024,
    "vcpus": 1,
    "disk": 25,
    "locked": false,
    "status": "new",
    "kernel": null,
    "created_at": "2020-07-21T18:37:44Z",
    "features": [],
    "backup_ids": [ ],
    "next_backup_window": null,
    "snapshot_ids": [ ],
    "image": {},
    "volume_ids": [ ],
    "size": {},
    "size_slug": "s-1vcpu-1gb",
    "networks": {},
    "region": {},
    "tags": []
    },
    "links": {
    "actions": []
    }
}
`}</code></pre>
        <h3 {...{
          "id": "link-the-droplet-ip-to-the-student",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#link-the-droplet-ip-to-the-student",
            "aria-label": "link the droplet ip to the student permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Link the droplet IP to the student`}</h3>
        <p>{`To get the Droplet IP and link it to the student, we could get it from the response's `}<inlineCode parentName="p">{`droplet.networks.v4`}</inlineCode>{`. The `}<inlineCode parentName="p">{`droplet.networks`}</inlineCode>{` is an object that has two properties: 1. v4 array of IPs 2. v6 array of IPs`}</p>
        <p>{`Example:`}</p>
        <pre><code parentName="pre" {...{}}>{`"networks": {
  "v4": [
    {
      "ip_address": "104.131.186.241",
      "netmask": "255.255.240.0",
      "gateway": "104.131.176.1",
      "type": "public"
    }
  ],
  "v6": [
    {
      "ip_address": "2604:A880:0800:0010:0000:0000:031D:2001",
      "netmask": 64,
      "gateway": "2604:A880:0800:0010:0000:0000:0000:0001",
      "type": "public"
    }
  ]
},
`}</code></pre>
        <p>{`After getting the IP, we can set the user record in the db `}<inlineCode parentName="p">{`user.serverIp`}</inlineCode>{` to `}<inlineCode parentName="p">{`networks.v4[0].ip_address`}</inlineCode></p>
        <h3 {...{
          "id": "display-the-reward-and-how-it-could-be-redeemed",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#display-the-reward-and-how-it-could-be-redeemed",
            "aria-label": "display the reward and how it could be redeemed permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Display the reward and how it could be redeemed`}</h3>
        <p>{`The student can either redeem the reward from the lesson challenges page OR through the notification that'll appear on their profile page.`}</p>
        <p>{`From the challenge page:`}</p>
        <pre><code parentName="pre" {...{}}>{`Many students don't make it to JS4. For your hard efforts, here's your own free c0d3 server

<BUTTON> -> Once clicked, it'll create a server on DigitalOcean

<SECTION> -> FAQ
E.g, What will happens if I stopped using the server?
`}</code></pre>
        <h3 {...{
          "id": "issues",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#issues",
            "aria-label": "issues permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Issues`}</h3>
        <h4 {...{
          "id": "how-to-monitor-student-access-to-their-servers",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#how-to-monitor-student-access-to-their-servers",
            "aria-label": "how to monitor student access to their servers permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`How to monitor student access to their servers`}</h4>
        <ol>
          <li parentName="ol">{`The server will expire after the student finish JS5`}
            <ul parentName="li">
              <li parentName="ul">{`Remove the server once they finish JS5`}</li>
            </ul>
          </li>
          <li parentName="ol">{`The server will exist as long as they use it`}
            <ul parentName="li">
              <li parentName="ul">{`"using it" means resources. They might have a server running that will use some of the resources. Monitoring their usage based on this metric won't work`}</li>
              <li parentName="ul">{`A better way is to monitor the most recent times they SSH-ed into the server. If the last time they accessed it is x days old, remove the server. To achieve this, we must create a custom image that'll have the SSH access monitoring system.`}</li>
            </ul>
          </li>
        </ol>
        <h3 {...{
          "id": "references",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#references",
            "aria-label": "references permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`References`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#1938"
            }}>{`Issue for this design doc`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.digitalocean.com/reference/api/api-reference"
            }}>{`DigitalOcean API`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/pjpimentel/dots"
            }}>{`DigitalOcean TS wrapper`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      